/* Include English Font */
$englishFontName: "Cairo";

@include fontFace($englishFontName, "../../fonts/Cairo-Light.ttf", normal, 300, "truetype");
@include fontFace($englishFontName, "../../fonts/Cairo-Regular.ttf", normal, 400, "truetype");
@include fontFace($englishFontName, "../../fonts/Cairo-SemiBold.ttf", normal, 600, "truetype");
@include fontFace($englishFontName, "../../fonts/Cairo-Bold.ttf", normal, 700, "truetype");
@include fontFace($englishFontName, "../../fonts/Cairo-Black.ttf", normal, 900, "truetype");

.englishFont {
    font-family: $englishFontName, sans-serif !important;
}
