#services-wrapper {
    .services-header {
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
        color: #205397;
        margin-top: 72px;

        @include mobile {
            margin-top: 40px;
            text-align: center;
            font-size: 20px;
        }
    }
    .desc {
        @include mobile {
            order: 2;
            justify-content: center;
        }
    }
    .image {
        @include mobile {
            order: 1;
        }
    }
    .services-description {
        font-size: 18px;
        width: 444px;
        color: #333333;
        line-height: 28px;
        margin-top: 41px;
        margin-bottom: 41px;
        @include mobile {
            width: 100%;
            order: 2;
            padding-left: 31px;
            margin-bottom: 30px;
        }
    }
    .services-btn {
        background-color: $orange;
        border-radius: 8px;
        padding: 16px 24px;
        text-transform: uppercase;
        font-size: 20px;
        color: $white;
        &:hover {
            text-decoration: none;
        }
        @include mobile {
            font-size: 18px;
            margin-top: 16px;
            display: block;
            text-align: center;
        }
    }
    .services-image {
        position: relative;
        bottom: 115px;
        width: 580.21px;
        height: 558.38px;
        @include mobile {
            width: 100%;
            height: 100%;
            bottom: unset;
        }
    }
    .our-offer-wrapper {
        .mask-image {
            width: 41.79px;
            height: 33px;
            margin-top: 16px;
        }
        .our-offer-header {
            font-size: 24px;
            font-weight: 700;
            line-height: 60px;
            color: #5f5f5f;
            @include mobile {
                text-align: center;
            }
        }
        .our-offer-box-header {
            width: 233px;
            height: 60px;
            font-size: 20px;
            font-weight: 700;
            line-height: 60px;
            color: #5f5f5f;
            @include mobile {
                width: 100%;
                // text-align: center;
            }
        }
        .our-offer-box-description {
            width: 340px;
            font-size: 18px;
            line-height: 28px;
            color: #333333;
            margin-bottom: 70px;
            @include mobile {
                width: 100%;
            }
        }
    }
}
