.sorry{
    height: 510px;
}
#meadia-center-wrapper {
    .meadia-center-banner {
        position: relative;
        .meadia-center-banner-img {
            height: 464px;
            width: 100%;
            color: #17498c;
            @include mobile {
                width: 100%;
                // width: 100%;
                height: 187px;
            }
        }
        .banner-content {
            position: absolute;
            top: 81.31px;
            left: 9%;
            @include mobile {
                position: absolute;
                top: 7px;
                left: 9%;
            }
            .banner-content-header {
                font-size: 50px;
                font-weight: 700;
                line-height: 60px;
                color: $white;
                width: 482px;
                height: 60px;
                @include mobile {
                    width: 114px;
                    font-size: 18px;
                }
            }
            .banner-content-description {
                font-size: 33px;
                font-weight: 700;
                line-height: 48px;
                color: $white;
                width: 435px;
                height: 96px;
                @include mobile {
                    width: 263px;
                    font-size: 16px;
                    line-height: 24.5px;
                }
            }
        }
    }
    .news-wrapper {
        margin-top: 66.73px;
        margin-bottom: 100px;
        @include mobile {
            margin-top: 40px;
            margin-bottom: 0;
        }
        .news-card {
            margin-bottom: 40px;
        }
        .mask-image {
            width: 41.79px;
            height: 33px;
            filter: drop-shadow(1px -1px 0#205397) drop-shadow(0 0 #205397);
            position: relative;
            @include mobile {
                width: 31.66px;
                height: 25px;
                filter: drop-shadow(0 -1px 0#205397) drop-shadow(0 0 #205397);
            }
        }
        .our-news-line {
            position: absolute;
            left: 36px;
            top: 20%;
            border-left: 2px dashed #17498c;
            height: 170px;
            margin-bottom: 30px;
            @include mobile{
                left: 31px;
                top: 13%; 
            height: 227px;

            }
        }
        .our-news-box-date {
            font-size: 18px;
            line-height: 24px;
            width: 99px;
            height: 24px;
            color: #205397;
            @include mobile {
                width: 100%;
                font-size: 14px;
            }
        }
        .our-news-box-title {
            font-size: 18px;
            font-weight: 700;
            color: #205397;
            height: 24px;
            margin-top: 10.14px;
            @include mobile {
                width: 100%;
                font-size: 14px;
            }
        }
        .our-news-box-description {
            width: 890px;
            height: 85px;
            font-size: 18px;
            line-height: 28px;
            color: #333333;
            margin-top: 7px;
            @include mobile {
                width: 233px;
                height: 100%;
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}
