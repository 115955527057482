#about-page-wrapper {
    .our-story {
        .our-story-header {
            font-size: 50px;
            font-weight: 700;
            color: #205397;
            line-height: 60px;
            margin-top: 113px;
            width: 100%;
            @include mobile {
                width: 100%;
                font-size: 20px;
                margin-top: 0;
                text-align: center;
            }
        }
        .our-story-description {
            margin-top: 77px;
            color: #333333;
            font-size: 18px;
            @include mobile {
                font-size: 14px;
                line-height: 24px;
                order: 99;
                margin-top: 0;
                margin-bottom: 33px;
                margin-left: 24px;
            }
        }
        .our-story-img {
            width: 100%;
            @include mobile {
                width: 100%;
                margin-left: 6px;
            }
        }
    }

    .expiriance {
        margin-top: 66px;
        .expiriance-img {
            width: 100%;
            // padding-right: 77px;
            margin-top: 50px;
            @include mobile {
                width: 100%;
                // height: 261px;
                margin-top: 0;
                margin-left: 6px;
            }
        }
        .expiriance-header {
            font-size: 50px;
            font-weight: 700;
            line-height: 60px;
            color: #eca917;
            margin-top: 50px;
            @include mobile {
                width: 100%;
                line-height: 40px;
                font-size: 20px;
                margin-top: 0;
                text-align: center;
            }
        }
        .expiriance-description {
            color: #333333;
            margin-top: 18.5px;
            @include mobile {
                line-height: 24px;
                font-size: 14px;
                margin-left: 24px;
            }
            .expiriance-description-label {
                padding-top: 30px;
                @include mobile {
                    padding-top: 25px;
                }
            }
        }
    }
    .our-clients {
        margin-bottom: 60px;
        .our-clients-header {
            color: #5c9b2e;
            font-size: 50px;
            font-weight: 700;
            width: 410px;
            margin-top: 60.62px;
            line-height: 60px;
            @include mobile {
                width: 100%;
                font-size: 20px;
                // margin-bottom: 60px;
                text-align: center;
            }
        }
        .our-clients-description {
            color: #333333;
            font-size: 18px;
            width: 100%;
            // height: 363px;
            margin-top: 19px;
            @include mobile {
                font-size: 14px;
                line-height: 24px;
                // width: 268px;
                // height: 264;
                margin-top: 0;
                margin-left: 24px;
            }
            .our-clients-description-label {
                padding-top: 30px;
                @include mobile {
                    padding-top: 30px;
                }
            }
        }
        .our-clients-img {
            margin-top: 99.55px;
            width: 100%;
            height: 590.33px;
            @include mobile {
                margin-top: 0;
                width: 100%;
                height: 313px;
            }
        }
    }
    .our-mission {
        // margin-left: 24px;

        .our-mission-img {
            margin-top: 66.12px;
            height: 553.69px;
            margin-bottom: 71.2px;
            @include mobile {
                margin-top: 0;
                width: 100%;
                height: 292px;
                margin-left: 30px;
            }
        }
        .our-mission-header {
            color: #f96611;
            font-size: 30px;
            font-weight: 700;
            width: 100%;
            margin-top: 66.12px;
            line-height: 60px;
            @include mobile {
                font-size: 20px;
                line-height: 40px;
                width: 251px;
                height: 40px;
                margin-top: 0;
                margin-left: 24px;
            }
        }
        .our-mission-description {
            color: #333333;
            font-size: 18px;
            width: 100%;
            margin-top: 19px;
            @include mobile {
                line-height: 24px;
                margin-top: 0;
                margin-left: 24px;
                font-size: 14px;
            }
        }
        .our-vision-header {
            color: #f96611;
            font-size: 30px;
            font-weight: 700;
            // width: 174px;
            width: 100%;
            // height: 60px;
            line-height: 60px;
            @include mobile {
                font-size: 20px;
                line-height: 40px;
                // width: 251px;
                // height: 40px;
                margin-left: 24px;
            }
        }
        .our-vision-description {
            @include mobile {
                margin-left: 24px;
            }
        }
    }
}
