#home-page-warpper {
    .banner-section {
        position: relative;
        background-color: #1f5296;
        @include desktop {
            height: 395.28px;
        }
        @include mobile {
            width: 100%;
        }

        .banner-image {
            // width: 674.66px;
            height: 396.28px;
            @include mobile {
                position: relative;
                height: 209px;
                width: 100%;
                right: 100px;
            }
        }

        .banner-content {
            position: absolute;
            top: 0;
            right: 5%;
            .lets-create-text {
                margin-top: 79px;
                font-size: 22px;
                font-weight: 700;
                color: #99c07d;
                @include mobile {
                    margin-top: 50px;
                    font-size: 10px;
                    margin-bottom: 0;
                    top: 0;
                    right: 7%;
                }
            }
            .kastana-text {
                font-size: 40px;
                color: $white;
                font-weight: 700;
                margin-top: 12px;
                max-width: 476px;
                @include mobile {
                    font-size: 18px;
                    max-width: 200px;
                    margin-top: 0;
                }
            }
            .get-started-btn {
                display: flex;
                justify-content: center;
                padding-top: 16.5px;
                padding-bottom: 16.5px;
                color: $white;
                background-color: $orange;
                border-radius: 8px;
                width: 174px;
                text-transform: uppercase;
                font-size: 20px;
                &:hover {
                    text-decoration: none;
                }
                @include mobile {
                    font-size: 14px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    width: 167px;
                }
            }
        }
    }
    .what-we {
        color: #5f5f5f;
        font-size: 30px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        height: 56px;
        margin-top: 28.87px;
        @include mobile {
            display: flex;
            justify-content: center;
            font-size: 20px;
            color: #5f5f5f;
            font-weight: 700;
            margin-top: 14.48px;
            margin-bottom: 9px;
            height: 0;
        }
    }
    #home-page-content {
        .our-services-card {
            @include mobile {
                margin-bottom: 34px;
            }
            .our-services-card-title {
                color: #333333;
                font-size: 24px;
                line-height: 40px;
                font-weight: 700;
                // min-height: 80px;
                max-width: 314px;
                margin-bottom: 19px;
                margin-top: 68px;
                @include mobile {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 40px;
                    margin-bottom: 0;
                    &:first-child {
                        margin-top: 22px;
                    }
                }
            }
        }
        .our-services-card-description {
            font-size: 16px;
            color: #333333;
            max-width: 414px;
            @include desktop {
                min-height: 125px;
            }
            @include mobile {
                font-size: 12px;
                line-height: 19px;
                margin-bottom: 20px;
            }
        }
        .our-services-card-link {
            border: 1px solid #205397;
            text-transform: uppercase;
            border-radius: 8px;
            font-size: 16px;
            color: #205397;
            padding: 7px 34px;

            text-align: center;

            &:hover {
                text-decoration: none;
            }
            @include mobile {
                font-size: 14px;
            }
        }
    }

    #gereen-background {
        position: relative;
        margin-top: 53px;

        .bg-green {
            width: 100%;
            height: 356px;
            margin-top: 30px;
        }
        .green-background-content {
            background-image: url("../../images/greenbackground.jpg");
            min-height: 356px;
            padding: 69px 64px;
            @include mobile {
                min-height: 199px;
                padding: 15px 22px;
            }
            .green-background-paragraph {
                color: #daeecb;
                font-size: 40px;
                font-weight: 400;
                text-align: center;
                margin-bottom: 12px;
                @include desktop {
                    line-height: 50px;

                    &.second-part {
                        max-width: 896px;
                        margin-bottom: 48px;
                    }
                }
                @include mobile {
                    font-size: 18px;
                    margin-bottom: 0;
                    line-height: 28px;
                }
            }
            .green-background-span {
                text-transform: capitalize;
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                color: $white;
                @include mobile {
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .green-background-btn {
                background-color: $orange;
                border-radius: 8px;
                padding: 13px 49px;
                text-transform: uppercase;
                font-size: 20px;
                color: $white;
                &:hover {
                    text-decoration: none;
                }
                @include mobile {
                    font-size: 14px;
                    padding: 10px 23px;
                    margin-top: 20px;
                }
            }
        }
    }
    #our-partners {
        margin-top: 84px;
        @include mobile {
            margin-top: 20px;
        }
        .our-partner-header {
            width: 187px;
            height: 56px;
            font-size: 30px;
            color: #5f5f5f;
            font-weight: 700;
            margin: auto;
            @include mobile {
                font-size: 20px;
                line-height: 28px;
                width: auto;
                height: auto;
                text-align: center;
                margin-bottom: 12px;
            }
        }
        .our-partner-paragraph {
            margin-top: 14px;
            color: #333333;
            font-size: 16px;
            text-align: center;
            @include desktop {
                width: 727px;
            }
            height: 125px;
            margin: auto;
            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .partner-logo {
            list-style: none;
            // margin-left: 119.08px;
            .umniah-logo {
                // width: 66.73px;
                height: 92.6px;
            }
            .orange-logo {
                // width: 91.98px;
                height: 90.95px;
            }
            .zain-logo {
                // width: 63.51px;
                height: 90.95px;
            }
            @include mobile {
                .umniah-logo {
                    // width: 66.73px;
                    height: 92.6px;
                }
                .orange-logo {
                    // width: 91.98px;
                    height: 90.95px;
                }
                .zain-logo {
                    // width: 63.51px;
                    height: 90.95px;
                }
            }
        }
        .be-our-partner {
            display: flex;
            justify-content: center;
            padding-top: 16.5px;
            padding-bottom: 16.5px;
            color: $white;
            background-color: $orange;
            border-radius: 8px;
            width: 167px;
            font-size: 20px;
            margin: auto;
            margin-top: 34px;
            margin-bottom: 53px;
            &:hover {
                text-decoration: none;
            }
            @include mobile {
                font-size: 14px;
                padding-top: 12px;
                padding-bottom: 12px;
                width: 110px;
                margin-bottom: 38px;
                margin-top: 28px;
            }
        }
        .mobile-carousel {
            width: 100%;
            .carousel-inner {
                .carousel-item {
                    .company-logo {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        img {
                            min-height: 125px;
                            max-height: 125px;
                            width: 126.42px;
                        }
                    }
                }
            }
            .carousel-control-prev {
                .carousel-control-prev-icon {
                    background-image: url("../../images/arrow/left.svg");
                    width: 24px;
                    height: 24px;
                }
            }
            .carousel-control-next {
                .carousel-control-next-icon {
                    background-image: url("../../images/arrow/right.svg");
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
