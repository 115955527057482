@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

// Custom large screen
@mixin desktop {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin fontFace($fontName, $fileName, $fontStyle, $fontWeight, $format) {
    @font-face {
        font-family: $fontName;
        font-style: $fontStyle;
        font-weight: $fontWeight;
        src: local($fontName), url($fileName) format($format);
    }
}
