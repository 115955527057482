@import "~bootstrap/scss/bootstrap";

@import "helpers/mixins";
@import "helpers/fonts";
@import "helpers/colors";
@import "layout/header";

@import "screens/home";
@import "screens/about";
@import "screens/services";
@import "screens/meadiaCenter";
@import "screens/contact";
@import "screens/salaes";
@import "layout/footer";
body {
    font-weight: 400;

    @extend .englishFont;
}
