#header {
    .triangle {
        display: inline;
        width: 0;
        height: 0;
        position: relative;
        top: 20px;
        left: 2px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #205397;
        @include mobile {
            top: 24px;
        }
    }
    padding-top: 20px;
    padding-bottom: 20px;
    @include mobile {
        padding-top: 8px;
        padding-bottom: 16px;
    }
    .logo {
        width: 167px;
        height: 126px;
        @include mobile {
            width: 83px;
            height: 62px;
        }
    }
    .burger-menu {
        width: 33px;
        height: 27px;
        margin-top: 23px;
        // margin-right: 17px;
    }
    .nav {
        @include desktop {
            flex-wrap: nowrap;
        }
        padding-top: 25px;
        .nav-item {
            &.active {
                .nav-link {
                    color: $orange;
                }
            }
            margin-right: 38px;
            .nav-link {
                color: $dark_blue;
                font-size: 18px;
                font-weight: 400;
                display: block;
            }
        }
        .services {
            &:hover .drop-down {
                display: block;
            }
        }
        .drop-down {
            background-color: #205397;
            position: absolute;
            z-index: 10000;
            border: 1px solid #205397;
            border-top-right-radius: 10%;
            border-bottom-left-radius: 10%;
            border-bottom-right-radius: 10%;
            margin-left: 15px;
            opacity: 0.985;
            display: none;
            .drop-down-link {
                display: block;
                font-size: 16px;
                line-height: 40px;
                color: $white;
                margin-left: 12px;
                margin-right: 30px;
                margin-bottom: 8px;
                text-decoration: none;
                &:last-child {
                    margin-bottom: 20px;
                }
                &:first-child {
                    margin-top: 8px;
                }
                &:hover {
                    color: $orange;
                    .triangle {
                        border-top: 5px solid $orange;
                    }
                }
            }
        }
    }

    #nav-mobile-view {
        width: 100%;
        height: 100%;
        background-color: #205397;
        opacity: 0.985;
        position: fixed;
        left: 0;
        overflow: auto;
        z-index: 99999999;
        top: 0;
        .close-btn {
            width: 27px;
            height: 27px;
            margin-top: 30px;
            margin-right: 21px;
        }
        .nav-item {
            list-style: none;
            text-align: center;
            padding: 0;

            .drop-down {
                background-color: #11386c;
                .nav-link {
                    font-size: 16px;
                    line-height: 40px;
                    color: $white;
                    font-weight: 400;
                }
            }
        }
        .nav-item li a {
            color: white;
            text-decoration: none;
            font-size: 20px;
            font-weight: 700;
        }
        .nav-item .triangle {
            border-top: 5px solid $white;
        }

        .social-meadia {
            list-style: none;
            display: flex;
            justify-content: center;
            // padding-bottom: 35px;
            .social-meadia-icon {
                width: 38.5px;
                height: 38.5px;
            }
        }
        .social-meadia li a {
            width: 27px;
            height: 27px;
            margin-top: 30px;
            margin-right: 21px;
            color: white;
        }
    }
}
