#footer {
    //margin-top: 40px;
    padding: 28px 80px;
    background: linear-gradient(#205397 0%, #174a8d 100%);
    @include mobile {
        padding: 19px 44px;
        margin-top: 0;
    }
    .footer-item-header {
        color: #8ba4ba;
        font-size: 16px;
        font-weight: 700;
    }
    .footer-list {
        list-style-type: none;
        padding-inline-start: 0;
        .footer-list-item {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            .footer-link {
                font-size: 18px;
                color: $white;
                line-height: 28px;
                &:hover {
                    text-decoration: none;
                    color: $orange;
                }
            }
        }
    }
    .footer-logo {
        width: 183px;
        height: 138px;
    }
    .social-media-block {
        @include mobile {
            display: flex;
            justify-content: center;
        }
        .social-media-icon {
            width: 38.06px;
            height: 38.06px;
            margin-right: 16px;
            &.last-icon {
                margin-right: 0;
            }
        }
    }
    .footer-newsletter {
        .footer-newsletter-body {
            color: #8ba4ba;
            font-size: 16px;
            line-height: 28px;
            width: 231px;
            height: 56px;
        }
        .footer-news-paper {
            color: #9b7f50;
            font-size: 16px;
            font-weight: 600;
            border-radius: 7px;
        }
        .input-with-arrow-block {
            .footer-input {
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
                border: unset;
                height: 47px;
                padding: 10px;
                &:focus-visible {
                    outline: unset;
                }
                &::placeholder {
                    color: #9b7f50;
                    font-size: 16px;
                }
            }
            .arrow-block {
                min-height: 47px;
                min-width: 47px;
                align-items: center;
                background-color: $orange;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
                .footer-arrow {
                    width: 32px;
                    height: 27px;
                    margin-left: 6px;
                }
            }
        }
    }
}
