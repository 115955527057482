#contact-page-wrapper {
    .contact-us-banner {
        position: relative;
        .contact-us-img {
            // width: 1023.47px;
            width: 100%;
            margin: auto;
            height: 469.95px;
            @include mobile {
                // width: 422.5px;
                width: 100%;
                height: 194px;
            }
        }
        .banner-content {
            position: absolute;
            top: 85.18px;
            left: 37.5px;
            @include mobile {
                top: 26.18px;
                left: 17.5px;
            }
            .contact-us-text {
                font-size: 50px;
                font-weight: 700;
                line-height: 50px;
                color: $white;
                margin-bottom: 8px;
                width: 482px;
                height: 60px;
                @include mobile {
                    font-size: 18px;
                    width: 100%;
                    height: 26px;
                    line-height: 26px;
                }
            }
            .contact-us-description {
                font-size: 33px;
                font-weight: 700;
                line-height: 48px;
                color: $white;
                width: 466px;
                height: 96px;
                margin-left: 4px;
                @include mobile {
                    width: 100%;
                    height: 56px;
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }
    #contact-us-body {
        margin-top: 87.23px;
        @include mobile {
            margin-top: 12px;
        }
        .mobile {
            @include mobile {
                display: flex;
            }
        }
        .social-media-block {
            .mobile-view-1 {
                // margin-left: 41px;
                margin-bottom: 77.94px;

                @include mobile {
                    order: 2;
                    margin-bottom: 41px;
                    display: flex;
                    justify-content: center;
                    img {
                        margin-left: 16px;
                    }
                }
            }
            .mobile-view-2 {
                @include mobile {
                    order: 1;
                    margin-bottom: 30px;
                }
                .kastana {
                    @include mobile {
                        margin-bottom: 15.94px;
                    }
                }
                .icon-common {
                    margin-bottom: 47.96px;
                    @include mobile {
                        margin-bottom: 21px;
                        align-items: unset !important;
                    }
                    .mailto {
                        text-decoration: none;
                        color: #333333;
                    }
                }
            }
            margin-left: 3px;
            @include mobile {
                display: flex;
                justify-content: center;
            }
            .social-media-icon {
                width: 38.06px;
                height: 38.06px;
                margin-right: 16px;
                @include desktop {
                    // margin-bottom: 54.94px;
                }
            }
            // .location {
            //     width: 309px;
            // }
        }
        .contact-us-item {
            padding-top: 77px;
            .social-media-icon {
                width: 38.06px;
                height: 38.06px;
                margin-right: 16px;
            }
            .text-desc {
                font-size: 18px;
                color: #333333;
                line-height: 28px;
                width: 320px;
                height: 56px;
                margin-top: 6px;
            }
        }
        .form {
            .input-common {
                width: 100%;
                border-radius: 8px;
                border: none;
                padding: 8px 16px;
                border: 1px solid #979797;
                &::placeholder {
                    font-size: 18px;
                    line-height: 28px;
                    color: #333333;
                }
                &:focus {
                    border: 1px solid #979797;
                }
                &:focus-visible {
                    outline: unset;
                }
            }

            .f-name-input {
                margin-bottom: 37px;
            }

            .email-input {
                margin-bottom: 25px;
            }
            .select-input {
                margin-bottom: 30px;
                background-color: white;
            }

            .text-area-input {
                resize: none;

                margin-bottom: 26px;
            }
            .btn-contact {
                display: flex;
                justify-content: end;
                @include mobile {
                    display: flex;
                }
            }
            .form-submit-input {
                width: 167px;
                height: 63px;
                padding: 12px 65px;
                border-radius: 8px;
                background-color: #eca917;
                color: $white;
                border: none;
                @include mobile {
                    width: 100%;
                }
            }
        }
    }
    #google-map {
        margin-top: 55px;
        .gmap_canvas{
            width: 100%;
            height: 326px;
            @include mobile{

            }
        }
    }
}
