#sales-wapper {
    .sales-banner {
        position: relative;
        .sales-img {
            width: 100%;
            height: 471px;
            @include mobile {
                width: 100%;
                height: 194px;
            }
        }
        .content-banner {
            position: absolute;
            top: 89.4px;
            left: 47px;
            @include mobile {
                top: 26px;
                left: 12px;
            }
            .banner-text-header {
                font-size: 50px;
                font-weight: 700;
                line-height: 50px;
                color: $white;
                width: 100%;
                @include mobile {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
            .banner-text-description {
                font-size: 33px;
                line-height: 48px;
                font-weight: 700;
                color: $white;
                width: 419px;
                height: 144px;
                @include mobile {
                    font-size: 16px;
                    line-height: 25px;
                    width: 100%;
                }
            }
        }
    }
    #sales-body {
        margin-top: 76.02px;
        .after-request {
            margin-right: 141px;
            .request-quastion {
                font-size: 24px;
                font-weight: 700;
                line-height: 34px;
                color: #333333;
                height: 68px;
            }
            .q-number {
                font-size: 30px;
                font-weight: 700;
                line-height: 34px;
                color: #333333;
                margin-right: 15px;
            }
            .q-answer {
                font-size: 18px;
                line-height: 28px;
            }
            .q-1 {
                margin-bottom: 27px;
            }
            .q-2 {
                margin-bottom: 29px;
            }
        }
    }
    .form {
        .input-common {
            width: 100%;
            // width: 388px;
            border-radius: 8px;
            border: none;
            padding: 8px 16px;
            border: 1px solid #979797;
            &::placeholder {
                font-size: 18px;
                line-height: 28px;
                color: #333333;
            }

            &::after {
                content: "*";
                color: red;
            }

            &:focus {
                border: 1px solid #979797;
            }
            &:focus-visible {
                outline: unset;
            }
            @include mobile {
                width: 100%;
            }
        }
        .f-name-input {
            margin-bottom: 37px;
        }
        .email-input {
            margin-bottom: 25px;
        }
        .select-input {
            margin-bottom: 30px;
            background-color: white;
        }
        .mobile-input {
            margin-bottom: 30px;
        }

        .text-area-input {
            resize: none;

            margin-bottom: 26px;
        }
        .btn-contact {
            display: flex;
            justify-content: end;
            margin-bottom: 110px;
            @include mobile {
                display: flex;
                margin-bottom: 40px;
                margin-top: 26px;
            }
        }
        .form-submit-input {
            width: 167px;
            height: 63px;
            padding: 12px 65px;
            border-radius: 8px;
            background-color: #eca917;
            color: $white;
            border: none;
            @include mobile {
                width: 100%;
            }
        }
    }
}
